import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_GENDER, CODE_SUPPORT_PROGRESS_STATUS, CODE_SUPPORT_EXEC_DIV, CODE_SUPPORT_FLOW_STATUS, CODE, CODE_RECEPTION, CONTACT_FORM, RESULT, REASON, CODE_HEALTH_GUIDANCE_CONTRACT_DIV, CODE_FAMILY, CODE_FRACTION } from "@/common/constants";

export default {
    async getList(data, page = 1, limit = 10) {
        return await Repository.post(`${ADMIN_REPOSITORY}/medical-indication`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async update(id, data) {
        return await Repository.put(`${ADMIN_REPOSITORY}/edit-medical-indication/${id}`, data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeReception() {
        try {
            return await Repository.get(`code_define?group=${CODE_RECEPTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getDetailTabA(id) {
        try {
            return await Repository.get(`/detail-patients/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getDetailTabB(id) {
        try {
            return await Repository.get(`/diagnostic-result/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContactForm() {
        try {
            return await Repository.get(`code_define?group=${CONTACT_FORM}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getResult() {
        try {
            return await Repository.get(`code_define?group=${RESULT}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getReason() {
        try {
            return await Repository.get(`code_define?group=${REASON}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getGender() {
        try {
            return await Repository.get(`code_define?group=${CODE_GENDER}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContractClient(id, param) {
        try {
            return await Repository.get(`contract-client/${id}`, param);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE_HEALTH_GUIDANCE_CONTRACT_DIV}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeFamily() {
        try {
            return await Repository.get(`code_define?group=${CODE_FAMILY}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportPattern() {
        try {
            return await Repository.get("health-support-pattern/list");
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportForm() {
        try {
            return await Repository.get("health-support-form/list-form-support",{support_form_div: "FIRST_SUPPORT"})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getMedicalGuidanceOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-organization/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getPaymentRepresentativeOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/payment-agencie/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationListContract() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContract`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationListContact() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContact`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeFraction() {
        try {
            return await Repository.get(`code_define?group=${CODE_FRACTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getReceiptMedical(receptions_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-reception/detail/${receptions_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async checkReceiptMedical(arr_patient_id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/detail-by-patient_id`,arr_patient_id)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getPatient(patient_id) {
        try {
            return await Repository.get(`/detail-health-guidance-reception/${patient_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContract(id) {
        try {
            return await Repository.get(`/client-organization-contract/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async save(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/create`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeSupportStatus() {
        try {
            return await Repository.get(`code_define?group=${CODE_SUPPORT_FLOW_STATUS}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeSupportExecStatus() {
        try {
            return await Repository.get(`code_define?group=${CODE_SUPPORT_EXEC_DIV}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeSupportProgressStatus() {
        try {
            return await Repository.get(`code_define?group=${CODE_SUPPORT_PROGRESS_STATUS}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async delete(id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/delete/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getClientOrganizationFormLayoutControl(insurer_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client_organization_form_layout_control/${insurer_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
