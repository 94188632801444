<template>
    <div class="row h-100">
        <div style="position:relative; top:-10px; left:10px;">
            <span style="cursor: pointer;" @click="toggleLeftPanel">
                <i class="fas fa-exchange-alt  fa-2x text-primary"></i>
            </span>
        </div>
        <div class="col-6 d-flex flex-column h-100 pt-0" id="slider-left">
            <div>
                <v-card-title ref="container" class="receipt-medical-title">
                    {{ $t('receiptMedical.receptionMedicalConfirm.title_1') }}
                </v-card-title>
                <!-- <v-divider /> -->
                <v-row>
                    <v-col>
                        <label for="halfway_finish">
                            {{ $t('receiptMedical.table.insurer') }}
                        </label>
                        <Select :items="listInsurerUser" v-model="formData.contact" v-on:input="changeDataContact" solo :disabled="!disabled" />
                        <Select :items="listContract" v-model="formData.contract" solo :disabled="!disabled" />
                    </v-col>
                    <v-col class="align-self-end mb-8">
                        <Button color="success" :rounded="false" @click="confirmCopyContract" :disabled="!disabled">
                        {{ $t('receiptMedical.copy_contract') }}
                        </Button>
                    </v-col>
                </v-row>
            </div>

            <!-- <v-divider /> -->
            <v-card-title ref="container" class="receipt-medical-title">
                {{ $t('receiptMedical.receptionMedicalConfirm.title_2') }}
            </v-card-title>
            <!-- <v-divider /> -->

            <div class="medical_2">
                <v-row>
                    <v-col cols="6">
                        <label for="halfway_finish">
                            {{ $t('receiptMedical.table.insurer') }}
                        </label>
                        <Select :items="listContractCopy" v-model="formData.contactCopy" solo :disabled="!disabled" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <label for="halfway_finish">
                            {{ $t('receiptMedical.contract_name') }}
                            <span class="required"></span>
                        </label>
                        <InputCommon
                            id="contract_name"
                            v-model="formData.contract_name"
                            type="text"
                            solo
                            :filled="false"
                            :rounded="false"
                            :disabled="!disabled"
                            :error-messages="errorsFormData.contract_name"
                        />
                    </v-col>
                </v-row>

                <v-row>
                <v-col cols="4">
                    <label for="year">
                        {{ $t('common.year') }}
                        <span class="required"></span>
                    </label>
                    <Select
                        solo
                        id="year"
                        :items="listYear"
                        v-model="formData.contract_fiscal_year"
                        :disabled="!disabled"
                        :error-messages="errorsFormData.contract_fiscal_year"
                        @input="onHandleChangeContractFiscalYear"
                    />
                </v-col>
                <v-col cols="3">
                    <label for="period">
                        {{ $t('common.period') }}
                    </label>
                    <Select
                        solo
                        id="period"
                        v-model="formData.health_guidance_period"
                        :items="periodOptions"
                        :disabled="!disabled || !isPeriodEditable"
                        :error-messages="errorsFormData.health_guidance_period"
                    />
                </v-col>
                <v-col cols="5">
                    <label for="code_contract">
                        {{ $t('receiptMedical.table.code_contract') }}
                        <span class="required"></span>
                    </label>
                    <Select
                        id="code_contract"
                        :items="listContractCode"
                        v-model="formData.health_guidance_contract_div"
                        solo
                        :disabled="!disabled"
                        :error-messages="errorsFormData.health_guidance_contract_div"
                    />
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <label for="health_guidance_code">
                        {{ $t('receiptMedical.table.health_guidance_code') }}
                        <span class="required"></span>
                    </label>
                    <Select
                        id="health_guidance_code"
                        :items="listHealthCode"
                        v-model="formData.health_guidance_div"
                        solo
                        :disabled="!disabled"
                        :error-messages="errorsFormData.health_guidance_div"
                    />
                </v-col>
                <v-col>
                    <label for="family_code">
                        {{ $t('receiptMedical.family_code') }}
                        <span class="required"></span>
                    </label>
                    <div
                        v-for="(item, index) in listFamilyCode"
                        :key="item.value + index + 'person_familiy_div'"
                    >
                        <input
                        :disabled="!disabled"
                        class="form-check-input"
                        v-model="formData.person_familiy_div"
                        :value="item.value"
                        type="radio"
                        :id="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                        />
                        <label
                        class="mr-8 form-check-label"
                        :for="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                        >
                        {{ item.text }}
                        </label>
                    </div>
                    <div class="messageErrorCustom" :class="{ showMessageCustom: errorsFormData.person_familiy_div}">
                        <span>{{errorsFormData.person_familiy_div}}</span>
                    </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="support_pattern">
                            {{ $t('receiptMedical.support_pattern') }}
                            <span class="required"></span>
                        </label>
                        <Select
                            id="support_pattern"
                            :items="listSupportPattern"
                            v-model="formData.health_support_pattern_id"
                            solo :disabled="!disabled"
                            :error-messages="errorsFormData.health_support_pattern_id"
                        />
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <label for="support_pattern">
                            支援担当外 参照可能レベル
                            <span class="required"></span>
                        </label>
                        <Select
                        id="out_of_support_patient_ref_level"
                        :disabled="!disabled"
                        :items="out_of_support_patient_ref_level_arr"
                        v-model="formData.out_of_support_patient_ref_level"
                        solo
                        />
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <v-row>
                <v-col>
                    <label for="initial_interview_format">
                        {{ $t('receiptMedical.initial_interview_format') }}
                        <span class="required"></span>
                    </label>
                    <Select
                        id="initial_interview_format"
                        :items="listStatusInitialPreview"
                        v-model="formData.first_support_form_id"
                        solo
                        :disabled="!disabled"
                        class="mt-3"
                        :error-messages="errorsFormData.first_support_form_id"
                    />
                </v-col>
                <v-col>
                    <label for="time_support">
                    {{ $t('receiptMedical.time_support') }}
                    <span class="required"></span>
                    </label>
                    <InputCommon
                        id="time_support"
                        name="continued_support_term"
                        :autocomplete="'off'"
                        :filled="false"
                        v-model="formData.continued_support_term"
                        :rounded="false"
                        :type="'number'"
                        :disabled="!disabled"
                        solo
                        :error-messages="errorsFormData.continued_support_term"
                    />
                </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <label for="health_guidance_agency">
                            対象者入力フォームレイアウト
                            </label>
                            <Select
                            id="health_guidance_agency" :disabled="!disabled"
                            :items="client_organization_form_layout_control_arr"
                            v-model="formData.client_organization_form_layout_control_id"
                            solo
                            class="mt-3"
                            />
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>

                <v-row>
                <v-col>
                    <label for="health_guidance_agency">
                    {{ $t('receiptMedical.health_guidance_agency') }}
                    <span class="required"></span>
                    </label>
                    <Select
                        id="health_guidance_agency"
                        :items="listHealthAgency"
                        v-model="formData.health_guidance_organization_id"
                        solo
                        :disabled="!disabled"
                        class="mt-3"
                        :error-messages="errorsFormData.health_guidance_organization_id"
                    />
                </v-col>
                <v-col />
                </v-row>

                <v-row>
                <v-col>
                    <label for="payment_agency">
                        {{ $t('receiptMedical.payment_agency') }}
                    </label>
                    <Select
                        id="payment_agency"
                        :items="listPaymentAgency"
                        v-model="formData.payment_agency_id"
                        solo
                        class="mt-3"
                        :disabled="!disabled"
                    />
                </v-col>
                <v-col />
                </v-row>

                <v-row>
                <v-col>
                    <label for="contract_agency">
                    {{ $t('receiptMedical.contract_agency') }}
                    </label>
                    <Select
                        id="contract_agency"
                        :items="listContractAgency"
                        v-model="formData.contract_coord_organization_id"
                        solo
                        :disabled="!disabled"
                        class="mt-3"
                    />
                </v-col>
                <v-col />
                </v-row>

                <v-row>
                <v-col>
                    <label for="destination_institution">
                    {{ $t('receiptMedical.destination_institution') }}
                    </label>
                    <Select
                        id="destination_institution"
                        :items="listDestinationInstitution"
                        v-model="formData.addressee_organiation_id"
                        solo
                        :disabled="!disabled"
                        class="mt-3"
                    />
                </v-col>
                <v-col />
                </v-row>

                <v-row class="d-flex">
                    <v-col>
                        <label for="rounded_division">
                            {{ $t('receiptMedical.rounded_division') }}
                            <span class="required"></span>
                        </label>
                        <Select id="rounded_division"
                            :items="listfractionating_div"
                            v-model="formData.fractionating_div"
                            solo
                            :disabled="!disabled"
                            class="mt-3"
                            :error-messages="errorsFormData.fractionating_div"
                        />
                    </v-col>
                    <v-col>
                        <label for="contract_unit_price_xml">
                            {{ $t('receiptMedical.contract_unit_price_xml') }}
                            <span class="required"></span>
                        </label>
                        <InputCommon
                            id="contract_unit_price_xml"
                            name="contract_unit_price_01"
                            type="number"
                            v-model="formData.contract_unit_price_01"
                            solo
                            :disabled="!disabled"
                            :filled="false"
                            :rounded="false"
                            suffix="円"
                            :error-messages="errorsFormData.contract_unit_price_01"
                        />
                    </v-col>
                    <v-col>
                        <label for="contract_unit_price_billing">
                            {{ $t('receiptMedical.contract_unit_price_billing') }}
                            <span class="required"></span>
                        </label>
                        <InputCommon
                            id="contract_unit_price_billing"
                            name="contract_unit_price_02"
                            type="number"
                            v-model="formData.contract_unit_price_02"
                            solo
                            :disabled="!disabled"
                            :filled="false"
                            :rounded="false"
                            suffix="円"
                            :error-messages="errorsFormData.contract_unit_price_02"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <label for="payment_setting">
                        {{ $t('receiptMedical.payment_setting') }}
                    </label>
                    <div class="d-flex justify-content-between" style="">
                        <v-col class="pl-0">
                            <label for="start">
                                {{ $t('common.start') }}
                                <span class="required"></span>
                            </label>
                            <InputCommon
                                id="start"
                                type="number"
                                :rounded="false"
                                :filled="false"
                                v-model="formData.start_pyament_rate" :disabled="!disabled"
                                solo
                                suffix="%"
                                :error-messages="errorsFormData.start_pyament_rate"
                            />
                        </v-col>
                        <v-col>
                            <label for="finish">
                                {{ $t('common.finish') }}
                                <span class="required"></span>
                            </label>
                            <InputCommon
                                id="finish"
                                type="number"
                                :rounded="false"
                                :filled="false"
                                v-model="formData.final_eval_payment_rate"
                                solo
                                :disabled="!disabled"
                                suffix="%"
                                :error-messages="errorsFormData.final_eval_payment_rate"
                            />
                        </v-col>
                        <v-col class="pr-0">
                            <label for="halfway_finish">
                                {{ $t('common.halfway_finish') }}
                                <span class="required"></span>
                            </label>
                            <InputCommon
                                id="halfway_finish"
                                type="number"
                                :rounded="false"
                                :filled="false"
                                v-model="formData.retire_payment_rate"
                                solo
                                :disabled="!disabled"
                                suffix="%"
                                :error-messages="errorsFormData.retire_payment_rate"
                            />
                        </v-col>
                    </div>
                </v-row>
            </div>

            <v-divider />
            <v-card-actions
                    class="d-flex justify-content-between position-fixed bottom-0 bg-white start-0 "
                    :class="isToggedFooter ? 'w-50' : 'w-40 ml-20r'"
            >
                <div>
                    <Button color="default" :rounded="false" @click="clearAll()">
                    <v-icon>mdi-delete</v-icon>
                        クリア
                    </Button>
                </div>
                <div class="d-flex justify-content-around gap-5 mt-5">
                    <Button @click="medicalDesignateReceptionConfirm" :disabled="!disabled" color="info" :rounded="false">
                        {{ $t('receiptMedical.receptionMedicalConfirm.confirm') }}
                    </Button>
                </div>
            </v-card-actions>
        </div>
        <div class="col-6 d-flex flex-column h-100 pt-0" style="padding-bottom: 9.0rem !important;" id="slider-right">
            <MedicalDesignateReception
                    :isShow="!disabled"
                    :formData="formData"
                    :titleContractInformation="titleContractInformation"
                    :disabled="disabled"
                    :clearSearchData="clearSearchData"
                >
            </MedicalDesignateReception>
            <Dialog :isShow="isDialogCopy" persistent :maxWidth="600">
                <DialogCopyMedicalReception @confirmDialog="copyContract" @closeDialog="onHandleCloseDialog" :copyItem="copyItem"/>
            </Dialog>
        </div>
    </div>
</template>
<script>
import MedicalDesignatedReception from "@/services/api/MedicalDesignateReceptionConfirm";
import { mapMutations } from "vuex";
import Select from "@/components/common/Select"
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog";
import InputCommon from "@/components/common/InputCommon";
import moment from "moment";
import MedicalDesignateReception from "@/components/MedicalDesignateReception/ConfirmMedicalDesignateReception";
import DialogCopyMedicalReception from "@/components/MedicalDesignateReception/DialogCopyMedicalReception";
import { formCreateOrEditReceiptMedicalGroup } from "../../common/schemaValidation";
import { MESSAGE, OUT_OF_SUPPORT_PATIENT_REF_LEVEL, TOAST_TIME_OUT} from "@/common/constants";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import mixins from "@/mixins";

export default {
    name: "list",
    mixins: [mixins],
    data() {
        return {
            component: undefined,
            listContract: [],
            listContractCopy: [],
            listContact: [],
            listYear: this.getFiscalYearFromDateArr(true),
            listContractCode: [],
            listHealthCode: [],
            listStatusInitialPreview: [],
            listfractionating_div: [],
            listDestinationInstitution: [],
            listContractAgency: [],
            listSupportPattern: [],
            medicalDirectives: undefined,
            listPaymentAgency: [],
            listHealthAgency: [],
            listFamilyCode: [],
            dataCopyContract: [],
            getCodeReceptionArr: [],
            listInsurerUser: [],
            disabledButtonCopy: true,
            formData: {
                contract_fiscal_year: "",
                health_guidance_period: null,
                continued_support_term: "",
                fractionating_div: "",
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                contract: "",
                contactCopy: "",
                contact: "",
                contract_name: "",
                health_guidance_contract_div: "",
                reception_date: "",
                addressee_organiation_id: "",
                contract_coord_organization_id: "",
                health_guidance_div: "",
                health_support_pattern_id: "",
                payment_agency_id: "",
                health_guidance_organization_id: "",
                person_familiy_div: "",
                first_support_form_id: "",
                out_of_support_patient_ref_level: 0,
                client_organization_form_layout_control_id: null,
            },
            disabled: true,
            isDialogCopy: false,
            errorsFormData: {
                continued_support_term: "",
            },
            rulesValidate: undefined,
            titleContractInformation: "",
            clearSearchData: false,
            buttonCopyStatus: false,
            messages: MESSAGE,
            isToggedFooter: false,
            out_of_support_patient_ref_level_arr: [],
            client_organization_form_layout_control_arr: [],
            insurer_id: null,
            isLeftHide: false,
        };
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        year_reception() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        copyItem() {
            if (!this.formData.contact) return null
            return this.listInsurerUser.filter(v => v.value === this.formData.contact)[0]
        },
        periodOptions() {
            return this.getHealthGuidancePeriods(this.formData.contract_fiscal_year).map((v) => ({
                text: `第${v}期`,
                value: v,
            }));
        },
        // 期の切り替え可能な年度
        isPeriodEditable() {
            if (!this.formData.contract_fiscal_year) {
                return false
            }
            return this.getPeriodSwitchingYears().includes(this.formData.contract_fiscal_year);
        }
    },
    components: {
        Select,
        Button,
        Dialog,
        InputCommon,
        MedicalDesignateReception,
        DialogCopyMedicalReception
    },
    async created() {
        const out_of_support_patient_ref_level_arr = await this.getCodeDefine(OUT_OF_SUPPORT_PATIENT_REF_LEVEL);
        for (const [key, value] of Object.entries(out_of_support_patient_ref_level_arr)) {
            this.out_of_support_patient_ref_level_arr.push({
                text: value,
                value: parseInt(key),
            });
        };
        await this.setupInitialSelect();
        this.$root.$on("fnTogged", (data) => {
            this.isToggedFooter = data.isTogged;
        });
    },
    mounted() {
        $("#slider-left").closest(".container-fluid").css("height", "100%");
    },
    watch: {
        "formData.contract"() {
            this.disabledButtonCopy = true;
            if (this.formData.contact != null) {
                this.disabledButtonCopy = false;
            }
        },
        isToggedFooter(newVal) {
            this.isToggedFooter = newVal;
        }
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        toggleLeftPanel() {
            const sliderLeft = $("#slider-left");
            const sliderRight = $("#slider-right");

            if (this.isLeftHide) {
                sliderLeft.removeClass("col-1");
                sliderRight.removeClass("col-11");
                sliderLeft.addClass("col-6");
                sliderRight.addClass("col-6");
                this.isLeftHide = false;
            } else {
                sliderLeft.removeClass("col-6");
                sliderRight.removeClass("col-6");
                sliderLeft.addClass("col-1");
                sliderRight.addClass("col-11");
                this.isLeftHide = true;
            }
        },
        changeDataContact() {
            MedicalDesignatedReception.filterClientOrganizationListContact(this.formData.contact).then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listContract = [];
                this.formData.contract = null;
                res.data.data.map((x) => {
                    this.listContract.push({
                        value: x.id,
                        text: x.contract_name
                    });
                });
                if (this.listContract.length > this.messages.DISABLE_USER_FLAG_FALSE) {
                    this.formData.contract = this.listContract[0].value;
                }
            });
        },
        setupInitialSelect() {
            MedicalDesignatedReception.getClientOrganizationFormLayoutControl(0).then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.client_organization_form_layout_control_arr = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.client_organization_form_layout_control_arr.push({
                            text: x.layout_name,
                            value: x.id,
                        });
                    });
                }
            );

            MedicalDesignatedReception.getCodeMedicalIndication().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listContractCode = [{
                    text: "",
                    value: "",
                }]
                for (const [key, value] of Object.entries(res.data)) {
                    this.listContractCode.push(
                        {
                            text: `${value}`,
                            value: key
                        }
                    );
                }
            });
            MedicalDesignatedReception.getMedicalIndication().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listHealthCode = [{
                    text: "",
                    value: "",
                }]
                for (const [key, value] of Object.entries(res.data)) {
                    this.listHealthCode.push(
                        {
                            text: `${value}`,
                            value: key
                        }
                    );
                }
            });
            MedicalDesignatedReception.getCodeFamily().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                for (const [key, value] of Object.entries(res.data)) {
                    this.listFamilyCode.push({
                        text: value,
                        value: key
                    });
                }
            });
            MedicalDesignatedReception.getHealthSupportPattern().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listSupportPattern = [{
                    text: "",
                    value: "",
                }]
                res.data.data.map(x => {
                    this.listSupportPattern.push(
                        {
                            text: x.support_pattern_name,
                            value: x.id
                        }
                    )
                })
            });
            MedicalDesignatedReception.getHealthSupportForm().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listStatusInitialPreview = [{
                    text: "",
                    value: "",
                }]
                // 分割実施の選択肢を除く
                res.data.data.filter(x => x.map_system_code_04 != "2").forEach(x => {
                    this.listStatusInitialPreview.push(
                        {
                            text: x.support_form_name_disp_02,
                            value: x.id,
                        }
                    )
                })
            });
            MedicalDesignatedReception.getMedicalGuidanceOrganization().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                const default_health_guidance_organization_id = res.data.data.filter(x => x.default_flag == 1);
                if(default_health_guidance_organization_id.length > 0){
                    this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].id;
                }

                this.listHealthAgency = [{
                    text: "",
                    value: "",
                    default_flag: null,
                }]
                res.data.data.map((x) => {
                    this.listHealthAgency.push(
                        {
                            text: `[${x.organ_code}] ${x.organ_name}`,
                            value: x.id,
                            default_flag: x.default_flag,
                        }
                    );
                });
            });
            MedicalDesignatedReception.getPaymentRepresentativeOrganization().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listPaymentAgency = [{
                    text: "",
                    value: null,
                }]
                res.data.data.map(x => {
                    this.listPaymentAgency.push(
                        {
                            text: `[${x.agency_code}] ${x.agency_name}`,
                            value: x.id
                        }
                    )
                })
            });
            MedicalDesignatedReception.getCodeFraction().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listfractionating_div = [{
                    text: "",
                    value: ""
                }];
                for (const [key, value] of Object.entries(res.data)) {
                    this.listfractionating_div.push(
                        {
                            text: value,
                            value: key
                        }
                    );
                }
            });
            MedicalDesignatedReception.getClientOrganizationListContract().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listContractAgency = [{
                        text: "",
                        value: null,
                    }];
                    res.data.data.map((x) => {
                        this.listContractAgency.push(
                            {
                                text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                                value: x.id,
                            }
                        );
                    });
                }
            );
            MedicalDesignatedReception.getClientOrganizationListContact().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listInsurerUser = [{
                        text: "",
                        value: null,
                    }];
                    this.listContractCopy = [{
                        text: "",
                        value: null,
                    }];
                    res.data.data.map((x) => {
                        this.listInsurerUser.push(
                            {
                                text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                                value: x.id,
                            }
                        );
                        this.listContractCopy.push(
                            {
                                text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                                value: x.id,
                            }
                        );
                    });
                }
            );
            MedicalDesignatedReception.getClientOrganizationContact().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listDestinationInstitution = [{
                        text: "",
                        value: null,
                    }]
                    res.data.data.map((x) => {
                        this.listDestinationInstitution.push(
                            {
                                text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                                value: x.id,
                            }
                        );
                    });
                }
            );
        },
        validateMedicalReception(field) {
            formCreateOrEditReceiptMedicalGroup
                .validateAt(field, this.formData)
                .then(() => {
                    this.errorsFormData[field] = "";
                })
                .catch((err) => {
                    this.errorsFormData[field] = err.message;
                });
        },
        confirmCopyContract() {
            if (!this.formData.contact) return
            this.isDialogCopy = true;
        },
        copyContract() {
            this.setIsLoading(true);
            this.isDialogCopy = false;
            if (!this.formData.contract) {
                return this.clearAll();
            }
            MedicalDesignatedReception.getOrganizationContract(this.formData.contract).then((res) => {
                // this.listContractCopy = [];
                this.setIsLoading(false);
                const { status, error } = res;
                if (status != SUCCESS) {
                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                    return;
                }
                const data = res.data;
                // this.listContractCopy = this.listDestinationInstitution;
                this.formData.contactCopy = this.formData.contact;
                this.formData.contract_fiscal_year = data.data.contract_fiscal_year;
                this.formData.health_guidance_period = data.data.health_guidance_period;
                this.formData.continued_support_term = data.data.continued_support_term;
                this.formData.fractionating_div = data.data.fractionating_div;
                this.formData.contract_unit_price_01 = data.data.contract_unit_price_01;
                this.formData.contract_unit_price_02 = data.data.contract_unit_price_02;
                this.formData.start_pyament_rate = data.data.start_pyament_rate;
                this.formData.final_eval_payment_rate = data.data.final_eval_payment_rate;
                this.formData.retire_payment_rate = data.data.retire_payment_rate;
                this.formData.contract_name = data.data.contract_name;
                this.formData.health_guidance_contract_div = data.data.health_guidance_contract_div;
                this.formData.reception_date = data.data.reception_date;
                this.formData.addressee_organiation_id = data.data.addressee_organiation_id;
                this.formData.contract_coord_organization_id = data.data.contract_coord_organization_id;
                this.formData.health_guidance_div = data.data.health_guidance_div;
                this.formData.health_support_pattern_id = data.data.health_support_pattern_id;
                this.formData.payment_agency_id = data.data.payment_agency_id;
                this.formData.health_guidance_organization_id = data.data.health_guidance_organization_id;
                this.formData.person_familiy_div = data.data.person_familiy_div;
                this.formData.first_support_form_id = data.data.first_support_form_id;
                this.formData.out_of_support_patient_ref_level = parseInt(data.data.out_of_support_patient_ref_level);
                this.formData.client_organization_form_layout_control_id = data.data.client_organization_form_layout_control_id;

                const default_health_guidance_organization_id = this.listHealthAgency.filter(x => x.default_flag == 1);
                if(default_health_guidance_organization_id.length > 0 && !data.data.health_guidance_organization_id && data.data.health_guidance_organization_id != undefined ){
                    this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].value;
                }
                this.$toast.open({
                    message: "契約情報コピー処理が正常終了しました。",
                    type: "success",
                });
            });
        },
        medicalDesignateReceptionConfirm() {
            formCreateOrEditReceiptMedicalGroup
                .validate(this.formData, { abortEarly: false })
                .then(() => {
                    this.errorsFormData = {}
                    this.disabled = false;
                    this.disabledButtonCopy = true;
                    this.titleContractInformation = this.listInsurerUser.filter(x => x.value == this.formData.contactCopy)[0].text
                                                        + "/" + this.formData.contract_fiscal_year.slice(-2)+"年度"
                                                        + "/" + this.listContractCode.filter(x => x.value == this.formData.health_guidance_contract_div)[0].text
                                                        + "/" + this.listHealthCode.filter(x => x.value == this.formData.health_guidance_div)[0].text
                                                        + "/" + this.listFamilyCode.filter(x => x.value == this.formData.person_familiy_div)[0].text
                })
                .catch((err) => {
                    let listError = {}
                    err.inner.forEach((error) => {
                        listError[error.path] = error.message;
                    });
                    this.errorsFormData = listError
                });
        },
        clearAll() {
            this.listContract = [];
            this.formData = {
                contract_fiscal_year: "",
                continued_support_term: "",
                fractionating_div: "",
                contract_unit_price_01: "",
                contract_unit_price_02: "",
                start_pyament_rate: "",
                final_eval_payment_rate: "",
                retire_payment_rate: "",
                contract: "",
                contactCopy: "",
                contact: "",
                contract_name: "",
                health_guidance_contract_div: "",
                reception_date: "",
                addressee_organiation_id: "",
                contract_coord_organization_id: "",
                health_guidance_div: "",
                health_support_pattern_id: "",
                payment_agency_id: "",
                person_familiy_div: "",
                first_support_form_id: "",
                health_guidance_organization_id: "",
                out_of_support_patient_ref_level: 0,
                client_organization_form_layout_control_id: null,
            };
            const default_health_guidance_organization_id = this.listHealthAgency.filter(x => x.default_flag == 1);
            if(default_health_guidance_organization_id.length > 0){
                this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].value;
            }

            this.clearSearchData = true;
            this.disabled = true;
            this.formData.contact = null;
            this.formData.contactCopy = null;
            this.setIsLoading(false);
        },
        onHandleCloseDialog() {
            this.isDialogCopy = false;
        },
        onHandleChange(field, value) {
            if (value === undefined || value === null) return;
            this.formData[field] = value.trim();
            this.validateMedicalReception(field);
        },

        /**
         * 年度変更時に期を変更する
         * @param {*} fiscalYear 年度
         */
        onHandleChangeContractFiscalYear(fiscalYear) {
            const periods = this.getHealthGuidancePeriods(fiscalYear);
            if (periods.length == 1) {
                this.formData.health_guidance_period = periods[0]
            } else if (periods.length == 2) {
                this.formData.health_guidance_period = periods[1];
            }
        }
    },
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.medical_2
    overflow-x: hidden
    height: 850px
.ml-20r
    margin-left: 20%
.w-40
    width: 40%
.receipt-medical-title
    font-size: 16px
    padding: 0 10px
    background-color: #d8d8d8
</style>
