<template>
  <v-sheet
    :color="color"
    :elevation="elevation"
    :width="width"
    :height="height"
    :outlined="outlined"
    :rounded="rounded"
    :shaped="shaped"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
    name: "Sheet",
    props: {
        width: {
            type: [String, Number],
            default: undefined
        },
        height: {
            type: [String, Number],
            default: undefined
        },
        outlined: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        shaped: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: undefined
        },
        elevation: {
            type: [String, Number],
            default: 1
        }
    }

};
</script>

<style scoped>

</style>