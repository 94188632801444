<template>
    <transition name="modal-fade">
        <div class="modal">
            <div class="modal-content d-flex bg-light w-50 rounded-2">
                <div class="modal-header align-items-start justify-center font-weight-bold"></div>
                <div class="p-4">
                    <ul>
                        <li>対象者ID: {{ patientId }}</li>
                        <li>{{ msg }}</li>
                    </ul>
                </div>
                <div class="modal-footer bg-secondary">
                    <button @click="$emit('close-modal')" class="w-100 text-white">{{ $t('common.close') }}</button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "AcceptedMedicalDesignate",
    props: {
        patientId: [String, Number],
        msg: [String],
    }
}
</script>


<style lang="scss" scoped>
.modal {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #000000da;
    height: 100%;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>