import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE, CODE_RECEPTION, CODE_HEALTH_GUIDANCE_CONTRACT_DIV, CODE_FAMILY, CODE_FRACTION } from "@/common/constants";

export default {
    async getMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeReception() {
        try {
            return await Repository.get(`code_define?group=${CODE_RECEPTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getContractClient(id) {
        try {
            return await Repository.get(`contract-client/list/${id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE_HEALTH_GUIDANCE_CONTRACT_DIV}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeFamily() {
        try {
            return await Repository.get(`code_define?group=${CODE_FAMILY}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportPattern() {
        try {
            return await Repository.get("health-support-pattern/list");
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportForm() {
        try {
            return await Repository.get("health-support-form/list");
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getMedicalGuidanceOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-organization/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getPaymentRepresentativeOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/payment-agencie/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationListContract() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContract`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async filterClientOrganizationListContact(client_organization_id) {
        try {
            return await Repository.get(`contract-client/list/${client_organization_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getOrganizationContract(id) {
        try {
            return await Repository.get(`/client-organization-contract/${id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationListContact() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listPatient`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeFraction() {
        try {
            return await Repository.get(`code_define?group=${CODE_FRACTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getReceiptMedical(id) {
        try {
            return await Repository.get(`/detail-health-guidance-reception/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async searchReceiptMedical(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/search`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async saveReceiptMedical(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/create`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async deleteReceiptMedical(id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/delete/${id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async handleBeforeApply(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/check-before-create-reception`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async clientOrganizationListContract() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContract`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationContact() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContact`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getReception(receptions_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-reception/detail/${receptions_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getClientOrganizationFormLayoutControl(insurer_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client_organization_form_layout_control/${insurer_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async checkReceiptMedical(arr_patient_id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-guidance-reception/detail-by-patient_id`,arr_patient_id)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
