<template>
    <v-card>
        <v-container>
            <v-card-title>
                {{ $t('common.confirm')}}
            </v-card-title>
            <div v-if="dialogContentMessage == 'checkedTwo'" class="m-3">
                <p>適用処理を実行します。よろしいですか？</p>
                <p>支援フローが再作成により、継続支援及び最終評価の支援内容が削除されます。</p>
            </div>

            <div v-if="dialogContentMessage == 'checkedRight'" class="m-3">
                <p>適用処理を実行します。よろしいですか？</p>
                <p>支援フローが再作成により、継続支援及び最終評価の支援内容が削除されます。</p>
            </div>

            <div v-if="dialogContentMessage == 'checkedLeft'" class="m-3">
                <p>適用処理を実行します。よろしいですか？</p>
            </div>

	        <div v-if="dialogContentMessage == 'unchecked'" class="m-3">
		        <p>適用処理の対象を一覧で選択してください。</p>
	        </div>

            <!-- <div v-if="dialogContentMessage != 'checkedRight' && dialogContentMessage != 'checkedTwo' && dialogContentMessage != 'checkedRightOnly'" class="m-3">
                <p v-for="item in dialogMessage" :key="item">{{ String(item).padStart(10, "0") + " 処理を実行します。よろしいですか？" }}</p>
                <p> {{ dialogContentMessage }}</p>
            </div> -->

            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
              <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.cancel') }}</button>
              <button v-if="dialogContentMessage == 'unchecked'" type="button" class="btn btn-primary" @click="$emit('closeDialog')">{{ $t('common.ok') }}</button>
              <button v-else type="button" class="btn btn-primary" @click="$emit('confirmDialog')">{{ $t('common.ok') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogWarningMedicalReception",
    props: {
        dialogMessage: [Array, Object],
        dialogContentMessage: [String]
    },
};
</script>

<style scoped>

</style>
