<template>
    <div class="slide" :class="[slideDisplay]">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Slide",
    computed: {
        slideDisplay() {
            return this.$store.state.slideDisplay
        }
    }
};
</script>
<style scoped lang="scss">
    .slide {
        z-index: 10;
        background: #fff;
        transition: right 0.5s;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        box-shadow: 0 0 2rem 0 rgb(0 0 0 / 5%);
        width: 80%;
        overflow-y: scroll;
        &.hide {
            right: -80%;
        }
    }
</style>