<template>
    <div class="d-flex flex-column h-100">
        <v-card-title ref="container" v-if="isShow" class="receipt-medical-title">
            {{ this.titleContractInformation }}
        </v-card-title>
        <!-- <v-divider /> -->
        <v-row v-if="isShow">
            <v-col>
                <div class="col-12 position-relative">
                    <div class="border rounded p-1 pr-2">
                        <span class="text-left">検索条件</span>
                    </div>
                    <v-list class="pt-0">
                        <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
                            <template #activator>
                                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
                            </template>
                            <v-list-item>
                                <div class="border border-top-0 rounded p-1 pr-2">
                                    <div class="row">
                                        <div class="col-3">
                                            受付状況
                                            <Select
                                                    id="code_reception"
                                                    :items="getCodeReceptionArr"
                                                    v-model="dataSearch.health_guidance_no_target"
                                                    :disabled="disabled"
                                                    solo
                                            />
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    未受付条件
                                                    <hr class="m-0" />
                                                    <div class="row">
                                                        <div class="col-12">
                                                            取込日時
                                                            <div class="row align-items-center justify-content-between">
                                                                <div class="col-5 mt-0 pt-0">
                                                                    <date-picker
                                                                            autocomplete="off"
                                                                            v-model="dataSearch.from_date"
                                                                            id="from_date"
                                                                            name="from_date"
                                                                            :config="options"
                                                                            :disabled="disabled"
                                                                            @input="validate('from_date')"
                                                                    ></date-picker>
                                                                </div>
                                                                <div class="col-1 text-center pt-0">-</div>
                                                                <div class="col-5 mt-0 pt-0">
                                                                    <date-picker
                                                                            autocomplete="off"
                                                                            v-model="dataSearch.to_date"
                                                                            id="to_date"
                                                                            name="to_date"
                                                                            :config="options"
                                                                            :disabled="disabled"
                                                                            @input="validate('to_date')"
                                                                    ></date-picker>
                                                                </div>
                                                                <span class="col-auto pt-0 text-danger">{{ errors.from_date }}</span>
                                                                <span class="col-auto pt-0 text-danger">{{ errors.to_date }}</span>
                                                                <span class="col-auto pt-0 text-danger">{{ In_ValRng_TermOver1 }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    受付済条件
                                                    <hr class="m-0" />
                                                    <div class="row">
                                                        <div class="col-12">
                                                受付日
                                                <div class="row align-items-center justify-content-between">
                                                    <div class="col-5 mt-0 pt-0">
                                                        <date-picker autocomplete="off"
                                                        v-model="dataSearch.from_date_reception"
                                                        id="from_date"
                                                        name="from_date"
                                                        :config="optionsReception"
                                                        :disabled="disabled"
                                                        @input="validate('from_date_reception')"
                                                        ></date-picker>
                                                    </div>
                                                    <div class="col-1 text-center pt-0">-</div>
                                                    <div class="col-5 mt-0 pt-0">
                                                        <date-picker
                                                        autocomplete="off"
                                                        v-model="dataSearch.to_date_reception"
                                                        id="to_date"
                                                        name="to_date"
                                                        :config="optionsReception"
                                                        :disabled="disabled"
                                                        @input="validate('to_date_reception')"
                                                        ></date-picker>
                                                    </div>
                                                    <span class="col-auto pt-0 text-danger">{{ errors.from_date_reception }}</span>
                                                    <span class="col-auto pt-0 text-danger">{{ errors.to_date_reception }}</span>
                                                    <span class="col-auto pt-0 text-danger">{{ In_ValRng_TermOver2 }}</span>
                                                </div>
                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 text-right mt-5 position-relative">
                                            <button class="btn btn-grey" @click="clearAllSearch" :disabled="disabled">
                                                <i class="fas fa-trash-alt"></i>
                                                クリア
                                            </button>
                                            <button class="btn btn-success position-absolute" style="width:80%" @click="searchReceiptMedical" :disabled="disabled">
                                                <i class="fas fa-search"></i>
                                                検索
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </div>
            </v-col>
        </v-row>
        <div v-if="isShow" class="container-fluid m-0 p-0">
            <div class="row no-gutters justify-content-end">
                <div class="col-auto mr-auto pt-8 ml-5">
                    ({{ (dataSearch.receptionIds?.length ?? 0) }} / {{ (dataMedicalReception?.length ?? 0) }})
                </div>
                <div class="col-4">
                    <Select
                        id="health_guidance_code"
                        class="mb-0"
                        :items="health_guidance_divs"
                        v-model="check_health_guidance_div"
                        solo
                    />
                </div>
                <div class="col-auto">
                    <button @click="checkNotCreateByCond(check_health_guidance_div)" type="button" class="btn btn btn-outline-secondary btn-sm mx-3 mt-1">
                    選択
                    </button>
                </div>
            </div>
        </div>
        <v-row v-if="isShow" class="overflow-auto">
            <v-col class="d-flex h-100">
                <div class="data-table-container data-table-container-index d-flex p-0">
                    <v-data-table
                        id="tableHighlightOne"
                        :headers="headersListNotCreate"
                        :items="dataMedicalReception"
                        :fixed-header="true"
                        :height="'100%'"
                        disable-pagination
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 data-table table table-responsive table-hover tableHighlightOne m-0"
                    >
                        <template v-slot:header="{ props: { headers } }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th
                                        v-for="(item, index) in headers"
                                        :key="item.text + 'header' + index"
                                        class="text-nowrap"
                                        v-bind:class="[
                                        item.sortable ? 'sortable' : '',
                                        sortByHeadersListNotCreate.sortBy == item.value
                                            ? 'active'
                                            : '',
                                        sortByHeadersListNotCreate.descending
                                            ? 'desc'
                                            : 'asc',
                                        ]"
                                        @click="item.sortable ? changeSort(item.value) : ''"
                                    >
                                        <input
                                            v-if="index == 1"
                                            @click="checkAllNotCreate()"
                                            class="form-check-input check-all-list-not-create"
                                            type="checkbox"
                                        />
                                        <span v-else>{{ item.text }}</span>
                                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="(item, index) in items" :key="item.id + 'key' + index" :id="'highlightOne' + index" @click="changeCellHighlightOne(index)">
                                    <td class="text-nowrap">
                                        <span v-show="selectCheckbox" v-for="(data, i) in selectCheckbox" :key="data + i + index">
                                            <i v-if="
                                                data.status
                                                && data.patient_id == item.id
                                                || data.status
                                                && data.health_guidance_reception_id == item.health_guidance_reception_id
                                            " class="fas fa-check-circle text-success"></i>
                                            <i v-if="!data.status && data.patient_id == item.id"
                                            @click="showModalMedical(data.patient_id)" class="fas fa-exclamation-triangle text-danger"></i>
                                        </span>
                                    </td>

                                    <td class="text-nowrap" :key="item + 'dataCheckbox'">
                                        <input
                                            :ref="'checkbox-list-not-create-'+item.id"
                                            :id="item.id"
                                            :value="item.health_guidance_reception_id"
                                            type="checkbox"
                                            v-if="item.id"
                                            @click="checkNotCreate(item.id)"
                                            class="form-check-input checkbox-list-not-create"
                                        />
                                    </td>
                                    <td class="text-nowrap text-center">
                                        <input type="checkbox" v-if="item.reception_date"
                                               id="create_flow_support"
                                               :disabled="disabled"
                                               class="form-check-input checkbox-list-recreate"
                                               :value="item.id"
                                        />
                                    </td>
                                    <td class="text-nowrap">
                                        <span class="text-link" @click="getReceiption(item.id, item.health_guidance_reception_id)">
                                            {{ item.reception_date }}
                                        </span>
                                    </td>
                                    <td class="text-nowrap">
                                        <button v-if="item.reception_date" class="btn btn-danger pb-0 pt-0" :disabled="disabled" @click="deleteMedicalDesignate(item.health_guidance_reception_id, item.patient_name_kana, item.id)">
                                            {{ $t('receiptMedical.receptionMedicalConfirm.delete') }}
                                        </button>
                                    </td>
                                    <td class="text-nowrap">{{ String(item.id).padStart(10, "0") }}</td>
                                    <td class="text-nowrap">{{ item.patient_name_kana }}</td>
                                    <td class="text-nowrap">{{ item.patient_birthday }}/{{ item.patient_gender }}</td>
                                    <td class="text-nowrap">{{ item.use_ticket_ref_number }}</td>
                                    <td class="text-nowrap">{{ listHealthCode[item.patient_health_guidance_div] }}</td>
                                    <td class="text-nowrap">[{{ item.client_organ_code }}] {{ item.client_organ_name }}</td>
                                    <td class="text-nowrap">{{ item.insured_card_number }} / {{ item.insured_card_symbol }} / {{ item.insured_card_branch_code }}</td>
                                    <td class="text-nowrap">{{ item.healthexam_result_import_datetime }}</td>
                                    <td class="text-nowrap">{{ formatTime(item.updated_at, "Y-M-D HH:mm:ss") }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>

        <!-- <v-divider /> -->
        <v-card-actions class="d-flex justify-content-end position-fixed bottom-0 start-0 bg-white w-40 ml-60r">
            <div class="d-flex justify-content-around gap-5 align-center">
                <span class="text-danger mt-5">{{ formErrorData?.reception_date }}</span>
                <label for="to_date" class="mt-5">受付日
                    <span class="required"></span>
                </label>
<!--                <input type="date" class="mt-5" id="to_date" name="to_date" v-model="reception_date" :disabled="disabled" />-->
              <div class="col-5 mt-10 pt-0">
              <date-picker
                  autocomplete="off"
                  v-model="reception_date"
                  :config="optionsReception"
                  :disabled="disabled"
                  class="reception_date_index"
              ></date-picker>
              </div>
                <!-- handleBeforeApply -->
                <Button class="btn btn-primary mt-5" @click="checkReception('save')" :rounded="false" :disabled="disableButton">
                    <v-icon>mdi-database</v-icon> 適用
                </Button>
            </div>
        </v-card-actions>

        <AcceptedMedicalDesignate v-if="showModal" @close-modal="showModal = false" :msg="messErrPatient" :patientId="patientId" />
        <Dialog :isShow="isDialog" persistent :maxWidth="600">
            <!-- onHandleConfirmDialog -->
            <ConfirmDeleteMedicalDesignate :modalData="modalData" @confirmDialog="checkReception('delete')" @closeDialog="onHandleCloseDialog" />
        </Dialog>
        <Dialog :isShow="isDialogApply" persistent :maxWidth="600">
            <DialogSelectNotExits :dialogMessage="dialogMessage" @closeDialog="onHandleCloseDialog" />
        </Dialog>
        <Dialog :isShow="isDialogWarning" persistent :maxWidth="600">
            <DialogWarningMedicalReception
                    :dialogMessage="dialogMessage"
                    @confirmDialog="acceptedMedicalDesignate"
                    @closeDialog="onHandleCloseDialog"
                    :dialogContentMessage="dialogContentMessage"
            />
        </Dialog>
        <slide>
      <ReceiptMedical v-if="component === 'reception'" :isShowNavigation="randomString" :dataDetailId="idSelected" :dataReceptionsId="dataReceptionsId"
        :checkViewOrEdit="true" @reload="reload" />
    </slide>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import MedicalDesignatedReception from "@/services/api/MedicalDesignateReceptionConfirm";
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog";
import Select from "@/components/common/Select";
import { SUCCESS } from "@/constants";
import {
    STEP_STATUS_ONE,
    STEP_STATUS_TWO,
    STEP_STATUS_THREE,
    HEALTH_GUIDANCE_RECEPTION_FALSE,
    HEALTH_GUIDANCE_RECEPTION_TRUE,
    CODE_GENDER
} from "@/common/constants";
import { formSearchMedicalDesignatedReception, formHandleBeforeApply } from "../../common/schemaValidation";
import AcceptedMedicalDesignate from "@/components/AcceptedMedicalDesignate";
import ConfirmDeleteMedicalDesignate from "@/components/MedicalDesignateReception/ConfirmDeleteMedicalDesignate";
import DialogSelectNotExits from "@/components/MedicalDesignateReception/DialogSelectNotExits";
import DialogWarningMedicalReception from "@/components/MedicalDesignateReception/DialogWarningMedicalReception";

import _ from "lodash";
import mixins from "@/mixins";
import Slide from "@/components/Slide";
import ReceiptMedical from "@/components/ReceiptMedicalDirectives/Modal/ReceiptMedical";

export default {
    name: "ConfirmMedicalDesignateReception",
    mixins: [mixins],
    components: {
        Button,
        Dialog,
        Select,
        AcceptedMedicalDesignate,
        ConfirmDeleteMedicalDesignate,
        DialogSelectNotExits,
        DialogWarningMedicalReception,
        ReceiptMedical,
        Slide
    },
    data() {
        return {
            component: undefined,
            idSelected: undefined,
            dataReceptionsId: null,
            messErrPatientArray: [],
            messErrPatient: null,
            patientId: null,
            sortByHeadersListNotCreate: {
                sortBy: null,
                descending: false,
            },
            headersListNotCreate: [
                { text: "", align: "start", sortable: false, value: "" },
                { text: "", sortable: false, value: "" },
                { text: "支援フロー再作成", sortable: false, value: "create_support_flow" },
                { text: "受付日", sortable: true, value: "reception_date" },
                { text: "受付取消", sortable: true, value: "delete_reception" },
                { text: "対象者ID", sortable: true, value: "id" },
                { text: "氏名(カナ)", sortable: true, value: "patient_name_kana" },
                { text: "生年月日/性別", sortable: true, value: "dob" },
                { text: "利用券整理番号", sortable: true, value: "use_ticket_ref_number" },
                { text: "保健指導区分", sortable: true, value: "patient_health_guidance_div" },
                { text: "保険者", sortable: true, value: "client_organ_code" },
                { text: "保険証 番号/記号/枝番", sortable: true, value: "insured_card" },
                { text: "取込日時", sortable: true, value: "healthexam_result_import_datetime" },
                { text: "更新日時", sortable: true, value: "updated_at" },
            ],
            headersListCreate: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "",
                    class: "text-nowrap",
                },
                {
                    text: "支援フロー再作成",
                    sortable: false,
                    value: "create_support_flow",
                    class: "text-nowrap",
                },
                {
                    text: "受付日",
                    sortable: false,
                    value: "reception_date",
                    class: "text-nowrap",
                },
                {
                    text: "受付取消",
                    sortable: false,
                    value: "delete_reception",
                    class: "text-nowrap",
                },
                {
                    text: "対象者ID",
                    sortable: false,
                    value: "id",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: false,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: false,
                    value: "dob",
                    class: "text-nowrap",
                },
                {
                    text: "保険者",
                    sortable: false,
                    value: "use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "契約区分",
                    sortable: false,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                {
                    text: "本家区分",
                    sortable: false,
                    value: "client_organ_code",
                    class: "text-nowrap",
                },
                {
                    text: "支払代行機関",
                    sortable: false,
                    value: "insured_card",
                    class: "text-nowrap",
                },
                {
                    text: "請求・報告先",
                    sortable: false,
                    value: "healthexam_result_import_datetime",
                    class: "text-nowrap",
                },
                {
                    text: "実施時点",
                    sortable: false,
                    value: "updated_at",
                    class: "text-nowrap",
                },
                { text: "", sortable: false, value: "", class: "text-nowrap" },
            ],
            selectCheckbox: [],
            dataMedicalReception: [],
            listCodeReception: [],
            getCodeReceptionArr: [],
            dataSearch: {
                receptionIds: [],
                health_guidance_no_target: "10",
                from_date: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                from_date_reception: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD"),
                to_date_reception: moment(new Date()).format("YYYY-MM-DD")
            },
            showModal: false,
            isDialog: false,
            isDialogApply: false,
            dialogMessage: null,
            dialogContentMessage: null,
            isDialogWarning: false,
            dataCheckbox: [],
            disableButton: true,
            modalData: [],
            reception_date: moment(new Date()).format("YYYY-MM-DD"),
            dataHandleBeforeApply: {
                patient_ids: [],
                health_guidance_reception_ids: [],
                patient_ids_recreate: [],
            },
            stepStatus: 0,
            setValueInput: [],
            listHealthCode: [],
            code_gender: null,
            arrCheckHealthGuidanceReceptionId: [],
            formErrorData: {
                reception_date: ""
            },
            errors: {
                from_date: null,
                to_date: null,
                from_date_reception: null,
                to_date_reception: null
            },
            In_ValRng_TermOver1: null,
            In_ValRng_TermOver2: null,
            rotateNavBar: false,
            check_health_guidance_div: 1,
            health_guidance_divs: [],
        }
    },
    watch: {
        disabled() {
            if (this.disabled) {
                this.disableButton = true;
                this.dataMedicalReception = [];
                this.dataSearch = {
                    health_guidance_no_target: null,
                    from_date: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD 00:00:00"),
                    to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                    from_date_reception: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD"),
                    to_date_reception: moment(new Date()).format("YYYY-MM-DD")
                }
            }
        },

        "dataSearch.from_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver1 = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver1 = "取込日時の範囲は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver1 = null;
            }
        },
        "dataSearch.to_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver1 = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver1 = "取込日時の範囲は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver1 = null;
            }
        },
        "errors.from_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver1 = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver1 = "取込日時の範囲は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver1 = null;
            }
        },
        "errors.to_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver1 = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver1 = "取込日時の範囲は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver1 = null;
            }
        },


        "dataSearch.from_date_reception"() {
            if (this.dataSearch.from_date_reception && this.dataSearch.to_date_reception && !this.errors.from_date_reception && !this.errors.to_date_reception) {
                this.In_ValRng_TermOver2 = null
                let countDay = moment(this.dataSearch.to_date_reception).diff(moment(this.dataSearch.from_date_reception), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver2 = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver2 = null;
            }
        },
        "dataSearch.to_date_reception"() {
            if (this.dataSearch.from_date_reception && this.dataSearch.to_date_reception && !this.errors.from_date_reception && !this.errors.to_date_reception) {
                this.In_ValRng_TermOver2 = null
                let countDay = moment(this.dataSearch.to_date_reception).diff(moment(this.dataSearch.from_date_reception), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver2 = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver2 = null;
            }
        },
        "errors.from_date_reception"() {
            if (this.dataSearch.from_date_reception && this.dataSearch.to_date_reception && !this.errors.from_date_reception && !this.errors.to_date_reception) {
                this.In_ValRng_TermOver2 = null
                let countDay = moment(this.dataSearch.to_date_reception).diff(moment(this.dataSearch.from_date_reception), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver2 = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver2 = null;
            }
        },
        "errors.to_date_reception"() {
            if (this.dataSearch.from_date_reception && this.dataSearch.to_date_reception && !this.errors.from_date_reception && !this.errors.to_date_reception) {
                this.In_ValRng_TermOver2 = null
                let countDay = moment(this.dataSearch.to_date_reception).diff(moment(this.dataSearch.from_date_reception), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver2 = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver2 = null;
            }
        },
    },
    props: {
        formData: {
            default: 0,
        },
        disabled: {
            default: true
        },
        titleContractInformation: [Object, Array, String],
        clearSearchData: {
            default: false
        },
        isShow: Boolean,
    },
    computed: {
        ...mapState({
            slideDisplay: "slideDisplay"
        }),
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        optionsReception() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
    },
    async created() {
        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        await this.setupInitialSelect();
        this.listHealthCode = await this.getCodeDefine("00000700");
        $(document).keypress((e) => {
            if(e.which === 13) {
                this.searchReceiptMedical()
            }
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            closeModal: "hideSlide"
        }),
        healthGuidanceReceptionIdIsCheck (idCheck) {
            if (this.arrCheckHealthGuidanceReceptionId.includes(idCheck)) {
                return true
            }
            return false
        },
        changeSort(column) {
            this.sortByHeadersListNotCreate.descending = !this.sortByHeadersListNotCreate.descending;
            if(this.sortByHeadersListNotCreate.descending){
                this.dataMedicalReception = _.orderBy(this.dataMedicalReception,`${column}`,"desc");
            } else {
                this.dataMedicalReception = _.orderBy(this.dataMedicalReception,`${column}`,"asc");
            }
        },
        searchReceiptMedical() {

            formSearchMedicalDesignatedReception
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                    let check = true;
                    for (const[key, value] of Object.entries(this.errors)) {
                        if(value){
                            check = false;
                            break;
                        }
                    }
                    if(check && !this.In_ValRng_TermOver1 && !this.In_ValRng_TermOver1){
                        this.errors = {
                            from_date: null,
                            to_date: null,
                            from_date_reception: null,
                            to_date_reception: null
                        }
                        this.setIsLoading(true);
                        const thisInterface = this;
                        this.dataSearch.from_date = moment(this.dataSearch.from_date).format("YYYY-MM-DD HH:mm:ss");
                        this.dataSearch.to_date = moment(this.dataSearch.to_date).format("YYYY-MM-DD HH:mm:ss");

                        // remove checked
                        let value_checkbox_handle_result = document.getElementsByClassName("checkbox-list-not-create");
                        for (let i = 0; i < value_checkbox_handle_result.length; i++) {
                            if(value_checkbox_handle_result[i].checked){
                                value_checkbox_handle_result[i].checked = false
                            }
                        }
                        thisInterface.arrCheckHealthGuidanceReceptionId = []

                        if (this.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_FALSE)) {
                            MedicalDesignatedReception.searchReceiptMedical(this.dataSearch).then(function(res) {
                                window.scrollTo({
                                    behavior: "smooth",
                                    top: 0,
                                });
                                const { data, status, error } = res;
                                thisInterface.setIsLoading(false);
                                if (status !== SUCCESS) {
	                                thisInterface.$toast.error(`[${error?.messageID}] ${error?.message}`);
	                                return;
                                }
                                thisInterface.selectCheckbox = [];

                                thisInterface.dataMedicalReception = [];
                                data.data.map((x) => {
                                    thisInterface.dataMedicalReception.push(
                                        {
                                            id: x.id,
                                            reception_date: x.reception_date,
                                            patient_name_kana: x.patient_name_kana,
                                            patient_gender: thisInterface.code_gender[x.patient_gender],
                                            patient_birthday: x.patient_birthday,
                                            use_ticket_ref_number: x.use_ticket_ref_number,
                                            health_guidance_div: x.health_guidance_div,
                                            patient_health_guidance_div: x.patient_health_guidance_div,
                                            client_organ_code: x.client_organ_code,
                                            client_organ_name: x.client_organ_name,
                                            insured_card_number: x.insured_card_number,
                                            insured_card_symbol: x.insured_card_symbol,
                                            insured_card_branch_code: x.insured_card_branch_code,
                                            health_guidance_reception_id: x.health_guidance_reception_id,
                                            healthexam_result_import_datetime: x.healthexam_result_import_datetime,
                                            updated_at: x.updated_at,
                                        }
                                    );
                                });

                                thisInterface.disableButton = true;
                                if (thisInterface.dataMedicalReception.length > 0) {
                                    thisInterface.disableButton = false;
                                }

                                if (thisInterface.dataMedicalReception.length < 1) {
                                    thisInterface.notifyNoSearchResults();
                                }
                            });
                        } else if (this.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_TRUE)) {
                            MedicalDesignatedReception.searchReceiptMedical(this.dataSearch).then(function(res) {
                                window.scrollTo({
                                    behavior: "smooth",
                                    top: 0,
                                });
                                const { data, status, error } = res;
                                thisInterface.setIsLoading(false);
	                            if (status !== SUCCESS) {
		                            thisInterface.$toast.error(`[${error?.messageID}] ${error?.message}`);
		                            return;
	                            }
                                thisInterface.selectCheckbox = [];

                                thisInterface.dataMedicalReception = [];
                                data.data.map((x) => {
                                    thisInterface.dataMedicalReception.push(
                                        {
                                            id: x.id,
                                            reception_date: x.reception_date,
                                            patient_name_kana: x.patient_name_kana,
                                            patient_gender: thisInterface.code_gender[x.patient_gender],
                                            patient_birthday: x.patient_birthday,
                                            use_ticket_ref_number: x.use_ticket_ref_number,
                                            health_guidance_div: x.health_guidance_div,
                                            patient_health_guidance_div: x.patient_health_guidance_div,
                                            client_organ_code: x.client_organ_code,
                                            client_organ_name: x.client_organ_name,
                                            insured_card_number: x.insured_card_number,
                                            insured_card_symbol: x.insured_card_symbol,
                                            insured_card_branch_code: x.insured_card_branch_code,
                                            healthexam_result_import_datetime: x.healthexam_result_import_datetime,
                                            health_guidance_reception_id: x.health_guidance_reception_id,
                                            updated_at: x.updated_at,
                                        }
                                    );
                                });

                                thisInterface.disableButton = true;
                                if (thisInterface.dataMedicalReception.length > 0) {
                                    thisInterface.disableButton = false;
                                }

                                if (thisInterface.dataMedicalReception.length < 1) {
                                    thisInterface.notifyNoSearchResults();
                                }
                            });
                        } else {
                            MedicalDesignatedReception.searchReceiptMedical(this.dataSearch).then(function(res) {
                                window.scrollTo({
                                    behavior: "smooth",
                                    top: 0,
                                });
                                const { data, status, error } = res;

                                thisInterface.setIsLoading(false);
	                            if (status !== SUCCESS) {
		                            thisInterface.$toast.error(`[${error?.messageID}] ${error?.message}`);
		                            return;
	                            }
                                thisInterface.selectCheckbox = [];
                                thisInterface.dataMedicalReception = [];
                                data.data.map((x) => {
                                    thisInterface.dataMedicalReception.push(
                                        {
                                            id: x.id,
                                            reception_date: x.reception_date,
                                            patient_name_kana: x.patient_name_kana,
                                            patient_gender: thisInterface.code_gender[x.patient_gender],
                                            patient_birthday: x.patient_birthday,
                                            use_ticket_ref_number: x.use_ticket_ref_number,
                                            health_guidance_div: x.health_guidance_div,
                                            patient_health_guidance_div: x.patient_health_guidance_div,
                                            client_organ_code: x.client_organ_code,
                                            client_organ_name: x.client_organ_name,
                                            insured_card_number: x.insured_card_number,
                                            insured_card_symbol: x.insured_card_symbol,
                                            insured_card_branch_code: x.insured_card_branch_code,
                                            healthexam_result_import_datetime: x.healthexam_result_import_datetime,
                                            health_guidance_reception_id: x.health_guidance_reception_id,
                                            updated_at: x.updated_at,
                                        }
                                    );
                                });

                                thisInterface.disableButton = true;
                                if (thisInterface.dataMedicalReception.length > 0) {
                                    thisInterface.disableButton = false;
                                }

                                if (thisInterface.dataMedicalReception.length < 1) {
                                    thisInterface.notifyNoSearchResults();
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        async handleBeforeApply() {
            formHandleBeforeApply
                .validate({reception_date: this.reception_date}, { abortEarly: false })
                .then(() => {
                    this.formErrorData = {};
                    const thisInterface = this;
                    thisInterface.setIsLoading(true);
                    let value_checkbox_handle_result = document.getElementsByClassName("checkbox-list-not-create");
                    let value_checkbox_handle_result_recreate = document.getElementsByClassName("checkbox-list-recreate");
                    thisInterface.dataHandleBeforeApply.patient_ids = [];
                    thisInterface.dataHandleBeforeApply.health_guidance_reception_ids = [];
                    thisInterface.dataHandleBeforeApply.patient_ids_recreate = [];
                    for (let i = 0; i < value_checkbox_handle_result.length; i++) {
                        if(value_checkbox_handle_result[i].checked == true){
                            thisInterface.dataHandleBeforeApply.patient_ids.push(parseInt(value_checkbox_handle_result[i].id));
                            thisInterface.dataHandleBeforeApply.health_guidance_reception_ids.push(parseInt(value_checkbox_handle_result[i].value));
                        }
                    }
                    if (value_checkbox_handle_result_recreate.length) {
                        for (let i = 0; i < value_checkbox_handle_result_recreate.length; i++) {
                            if(value_checkbox_handle_result_recreate[i].checked === true){
                                thisInterface.dataHandleBeforeApply.patient_ids_recreate.push(parseInt(value_checkbox_handle_result_recreate[i].value));
                            }
                        }
                    }

                    // compare patient_ids with patient_ids_recreate
                    if (this.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_TRUE)) {
                        this.dialogContentMessage = "checkedLeft";
                        if (thisInterface.dataHandleBeforeApply.patient_ids_recreate.length) {
                            this.dialogContentMessage = "checkedTwo"
                        }

                        if (
                            JSON.stringify(thisInterface.dataHandleBeforeApply.patient_ids_recreate) !== JSON.stringify(thisInterface.dataHandleBeforeApply.health_guidance_no_target) &&
                            thisInterface.dataHandleBeforeApply.patient_ids_recreate.length > thisInterface.dataHandleBeforeApply.patient_ids.length
                        ) {
                            thisInterface.dataHandleBeforeApply.patient_ids = [];
                            this.dialogContentMessage = "checkedRight"
                        }
                    }

                    if (this.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_FALSE)) {
                        thisInterface.dataHandleBeforeApply.patient_ids = [];
                        this.dialogContentMessage = "checkedLeft";
                    }

                    // check left
                    if(this.dataHandleBeforeApply.health_guidance_reception_ids.length > 0){
                        this.dialogContentMessage = "checkedLeft";
                    }
                    // check right
                    if(this.dataHandleBeforeApply.patient_ids_recreate.length > 0){
                        this.dialogContentMessage = "checkedRight";
                    }
                    // check checkedTwo
                    if(this.dataHandleBeforeApply.patient_ids_recreate.length > 0 && this.dataHandleBeforeApply.health_guidance_reception_ids.length > 0){
                        this.dialogContentMessage = "checkedTwo";
                    }


                    thisInterface.stepStatus = STEP_STATUS_ONE;
                    if (thisInterface.dataHandleBeforeApply.patient_ids.filter(n => n)) {
                        let s = new Set(thisInterface.dataHandleBeforeApply.patient_ids.filter(n => n));
                        let it = s.values();
                        thisInterface.dataHandleBeforeApply.patient_ids = Array.from(it);
                    }
                    if (thisInterface.dataHandleBeforeApply.health_guidance_reception_ids.filter(n => n)) {
                        let s = new Set(thisInterface.dataHandleBeforeApply.health_guidance_reception_ids.filter(n => n));
                        let it = s.values();
                        thisInterface.dataHandleBeforeApply.health_guidance_reception_ids = Array.from(it);
                    }

                    MedicalDesignatedReception.handleBeforeApply(thisInterface.dataHandleBeforeApply).then(function (res) {
                        const { data, status, error } = res;
                        let medicalReceptionNotExits = [];
                        thisInterface.isDialogWarning = false;
                        thisInterface.setIsLoading(false);
                        if (status !== SUCCESS) {
	                        thisInterface.$toast.error(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }

                        data.data.forEach(function (code) {
                            if (!code.status) {
                                medicalReceptionNotExits.push(code.patient_id);
                                medicalReceptionNotExits.push(code.health_guidance_reception_id);
                            }
                        });

                        const flagMedicalReception = medicalReceptionNotExits.filter(x => {
                            return x != undefined && x.status != true
                        })

                        if (flagMedicalReception.length === 0) {
                            thisInterface.stepStatus = STEP_STATUS_THREE;
                        }

                        if (flagMedicalReception.length > 0) {
                            thisInterface.clearAllSearch();
                            thisInterface.dialogMessage = flagMedicalReception;
                            return thisInterface.isDialogApply = true;
                        }

                        if (thisInterface.stepStatus === STEP_STATUS_THREE) {
                            thisInterface.dialogMessage = thisInterface.dataHandleBeforeApply.patient_ids;
                            thisInterface.isDialogWarning = true;
                        }
                    });
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                    this.formErrorData = this.errors
                });
        },
        checkReception(action) {
            this.setIsLoading(true);
            const patien_ids = [];
            if(action == "save"){
                let value_checkbox_handle_result = document.getElementsByClassName("checkbox-list-not-create");
                for (let i = 0; i < value_checkbox_handle_result.length; i++) {
                    if(value_checkbox_handle_result[i].checked == true){
                        patien_ids.push(parseInt(value_checkbox_handle_result[i].id));
                    }
                }
            }else{
                patien_ids.push(this.dataMedicalReception.filter(x => x.health_guidance_reception_id == this.modalData.reception_id)[0].id);
            }

            if (patien_ids.length < 1) {
                this.setIsLoading(false);
                this.dialogContentMessage = "unchecked";
                this.isDialogWarning = true;
                return;
            }
            MedicalDesignatedReception.checkReceiptMedical(
                patien_ids
            ).then((res) => {
                this.setIsLoading(false);
                const { data, status, error } = res;
                if (status !== SUCCESS) {
	                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                    return;
                }
                if(data.data.length < 1){
                    this.handleBeforeApply();
                }else{
                    var checkSaveReceptions = true;
                    const receptions = data.data;
                    var count = 0;
                    for (let i = 0; i < receptions.length; i++) {
                        count++;
                        let health_support_flow = receptions[i].health_support_flows[0];

                        let checkSegment = true;
                        if(health_support_flow.health_support_flow_segments.filter(x => x.support_exec_div == 10 && x.support_progress_status == 10).length == 0){
                            checkSegment = false;
                        }

                        if(health_support_flow.support_flow_status != 10 || !checkSegment ){
                            checkSaveReceptions = false;
                            break;
                        }
                    }
                    if(count == receptions.length){
                        if(checkSaveReceptions){
                            if(action == "save"){
                                this.handleBeforeApply();
                            }else{
                                this.onHandleConfirmDialog(this.modalData.reception_id);
                            }
                        }else{
                            var mess = "入力エラー <br>データ状態が変更されているため、受付できません。表示情報を更新してください";
                            if(action == "save"){
                                mess = "入力エラー <br>データ状態が変更されているため、受付できません。表示情報を更新してください";
                            }
                            this.$toast.open({
                                message: mess,
                                type: "error",
                            });
                        }
                    }
                }
            });
        },
        async acceptedMedicalDesignate() {
            this.isDialogWarning = false;
            const thisInterface = this;
            thisInterface.arrCheckHealthGuidanceReceptionId = [];
            const dataAcceptedMedical = {...this.formData};
            const patient_ids = [];
            const health_guidance_reception_ids = [];
            let value_checkbox_handle_result = document.getElementsByClassName("checkbox-list-not-create");
            let create_flow_support = document.getElementById("create_flow_support");
            for (let i = 0; i < value_checkbox_handle_result.length; i++) {
                if(value_checkbox_handle_result[i].checked){
                    if (value_checkbox_handle_result[i].value) {
                        health_guidance_reception_ids.push(parseInt(value_checkbox_handle_result[i].value));
                    } else {
                        patient_ids.push(parseInt(value_checkbox_handle_result[i].id));
                    }
                    if (this.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_FALSE)) {
                        thisInterface.arrCheckHealthGuidanceReceptionId.push(parseInt(value_checkbox_handle_result[i].id))
                    }
                }
            }

            dataAcceptedMedical.create_flow_support = 0;
            const conditionFlowSupport = create_flow_support ? create_flow_support.checked : false;
            if (conditionFlowSupport) {
                dataAcceptedMedical.create_flow_supports = health_guidance_reception_ids;
            }
            dataAcceptedMedical.patient_ids = patient_ids;
            dataAcceptedMedical.health_guidance_reception_ids = health_guidance_reception_ids;
            dataAcceptedMedical.reception_date = this.reception_date;
            this.setIsLoading(true);
            MedicalDesignatedReception.saveReceiptMedical(dataAcceptedMedical).then(function (res) {
                thisInterface.messErrPatientArray = res.data.data;
                const { data, status, error } = res;
                thisInterface.setIsLoading(false);
                if (status !== SUCCESS) {
                    thisInterface.$toast.open({
                        message: "保険指導受付の登録が一部完了しました。(一部エラーあり)",
                        type: "error",
                    });
                    return ;
                }
                if(thisInterface.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_TRUE)) {
                    thisInterface.selectCheckbox = []
                }
                let flagCheckFail = false
                data.data.forEach(function (code) {
                    if (code.status) {
                        thisInterface.dataMedicalReception.forEach((element, index) => {
                            if (element.id == code.patient_id) {
                                thisInterface.$refs[`checkbox-list-not-create-${code.patient_id}`][0].checked = false;
                                thisInterface.dataMedicalReception[index].updated_at = moment(new Date()).format("YYYY-MM-DD HH:mm");
                                thisInterface.dataMedicalReception[index].reception_date = moment(dataAcceptedMedical.reception_date).format("YYYY/MM/DD");
                                thisInterface.dataMedicalReception[index].health_guidance_reception_id = code.health_guidance_reception_id;
                            }
                        });
                    } else {
                        flagCheckFail = true
                    }
                    if (code.hasOwnProperty("patient_id") ) {
                        thisInterface.selectCheckbox.push(code);
                    } else if(thisInterface.dataSearch.health_guidance_no_target == String(HEALTH_GUIDANCE_RECEPTION_TRUE)) {
                        thisInterface.selectCheckbox.push(code);
                    }
                });
                thisInterface.selectCheckbox = thisInterface.selectCheckbox.reduce((acc, current) => {
                    const x = acc.find(item => item.health_guidance_reception_id === current.health_guidance_reception_id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                if(flagCheckFail) {
                    thisInterface.$toast.open({
                        message: "保険指導受付の登録が一部完了しました。(一部エラーあり)",
                        type: "error",
                    });
                } else {
                    thisInterface.$toast.open({
                        message: "保険指導受付の登録が完了しました。",
                        type: "success",
                    });
                }
            });
        },
        /**
         * 対象選択 ヘッダチェックボックス
         */
        checkAllNotCreate() {
            this.dataSearch.receptionIds = [];
            let value = $(".checkbox-list-not-create");
            if (
                document.getElementsByClassName("check-all-list-not-create")[0].checked
            ) {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = true;
                    this.dataSearch.receptionIds.push(parseInt(value[i].id));
                }
            } else {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = false;
                }
            }
        },
        /**
         * 対象選択 行チェックボックス
         */
        checkNotCreate(id) {
            const check = this.dataSearch.receptionIds.filter((x) => x == id);
            if (check.length > 0) {
                this.dataSearch.receptionIds = this.dataSearch.receptionIds.filter(
                    (x) => x != id
                );
            } else {
                this.dataSearch.receptionIds.push(id);
            }
        },
        checkNotCreateByCond(hgdiv) {
            this.dataSearch.receptionIds = [];
            let value = $(".checkbox-list-not-create");
            const filteredIds = this.dataMedicalReception
                .filter(elm => {
                    return elm.patient_health_guidance_div == hgdiv
                })
                .map(elm => elm.id);

            for (let i = 0; i < value.length; i++) {
                const id = parseInt(value[i].id);
                if (filteredIds.some(x => {
                    return x == id;
                })) {
                    value[i].checked = true;
                    this.dataSearch.receptionIds.push(id);
                } else {
                    value[i].checked = false;
                }
            }
        },
        changeCellHighlightOne(index) {
            $(".cellHighlightOne").removeClass("cellHighlightOne");
            $("#highlightOne" + index).addClass("cellHighlightOne");
        },
        onHandleCloseDialog() {
            this.isDialog = false;
            this.isDialogApply = false;
            this.isDialogWarning = false;
        },
        onHandleConfirmDialog(reception_id) {
            this.isDialog = false;
            MedicalDesignatedReception.getReception(reception_id).then((res) => {
                const data = res.data.data;
                var checkDelete = false;
                if(data.health_support_flows.length > 0){
                    const health_support_flows = data.health_support_flows[0];
                    const health_support_flow_segment = _.orderBy(health_support_flows.health_support_flow_segments,"support_flow_seq","asc");
                    const get_health_support_flow_segment = health_support_flow_segment[0];
                    if(health_support_flows.support_flow_status == 10 && get_health_support_flow_segment.support_exec_div == 10 && get_health_support_flow_segment.support_progress_status == 10){
                        checkDelete = true;
                    }
                }
                if(checkDelete){
                    const thisInterface = this;
                    this.setIsLoading(true);
                    MedicalDesignatedReception.deleteReceiptMedical(reception_id).then(function (res) {
                        thisInterface.setIsLoading(false);
                        thisInterface.selectCheckbox = [];
                        if (res.status !== SUCCESS) {
	                        thisInterface.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`);
                            return;
                        }

                        thisInterface.dataMedicalReception = thisInterface.dataMedicalReception.filter(x => x.health_guidance_reception_id != reception_id);
                        thisInterface.searchReceiptMedical();
                        thisInterface.$toast.open({
                            message: "受付状況の更新が完了しました。",
                            type: "success",
                        });
                    });
                }else{
                    this.$toast.open({
                        message: "データ状態が変更されているため、受付できません。表示情報を更新してください。",
                        type: "error",
                    });
                }
            });
        },
        async deleteMedicalDesignate(reception_id, patient_name_kana, id) {
            this.modalData = { reception_id , patient_name_kana, id};
            this.isDialog = true;
        },
        checkAll() {
            let checkAll = document.getElementById("checkAll");
            let value_checkbox_handle_result = document.getElementsByClassName("value_checkbox_handle_result");
            for (let i = 0; i < value_checkbox_handle_result.length; i++) {
                if (checkAll.checked == true) {
                    value_checkbox_handle_result[i].checked = true
                } else {
                    value_checkbox_handle_result[i].checked = false
                }
            }
        },
        setupInitialSelect() {
            // MedicalDesignatedReception.getMedicalIndication().then((res) => {
            //     this.listHealthCode = res.data;
            // });
            MedicalDesignatedReception.getCodeReception().then((res) => {
                this.getCodeReceptionArr = [{
                    text: "",
                    value: null,
                }]
                for (const [key, value] of Object.entries(res.data)) {
                    this.getCodeReceptionArr.push({
                        text: `${value}`,
                        value: key,
                    });
                }
                this.getCodeReceptionArr.pop();
            });

            MedicalDesignatedReception.getMedicalIndication().then((res) => {
                for (const [key, value] of Object.entries(res.data)) {
                    this.health_guidance_divs.push(
                        {
                            text: `${value}`,
                            value: key
                        }
                    );
                }
                this.check_health_guidance_div = this.health_guidance_divs[0].value;
            });
        },
        clearAllSearch() {
            this.dataSearch = {
                health_guidance_no_target: null,
                from_date: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                from_date_reception: moment(new Date()).subtract(7,"d").format("YYYY-MM-DD"),
                to_date_reception: moment(new Date()).format("YYYY-MM-DD")
            };
            this.dataMedicalReception = [];
            this.setIsLoading(false);
            this.searchReceiptMedical();
        },
        showModalMedical(patient_id) {
            this.messErrPatient = "";
            let messErr = this.messErrPatientArray.filter(x => x.patient_id == patient_id && x.status == false);
            if(messErr.length > 0){
                this.messErrPatient = messErr[0].msg;
            }
            this.patientId = patient_id;
            this.showModal = true;
        },
        validate(field) {
            formSearchMedicalDesignatedReception
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errors[field] = null;
                })
                .catch((err) => {
                    this.errors[field] = err.message;
                });
        },
        getReceiption(id, health_guidance_reception_id) {
            this.idSelected = id;
            this.dataReceptionsId = health_guidance_reception_id;
            this.component = "reception";
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        reload(data, action) {
            this.idSelected = null;
            this.dataReceptionsId = null;
            if (action == "update" || action == "delete") {
                this.searchReceiptMedical();
            }
        },
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass">
.w-40
    width: 40%
.ml-60r
    margin-left: 60%
.data-table-container-index .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th
    z-index: 0
</style>
<style lang="sass" scoped>
@import "../../styles/common"
.percent
  top: 1px
  right: 1px
  font-size: 15px
  background: #e7e7e7
  padding: 7px
  border-radius: 0px 4px 4px 0px
.cellHighlight
  background-color: #d2e3fb
.cellHighlightOne
  background-color: #d2e3fb
.required:after
    content: " *"
    color: red
    font-weight: 100
.receipt-medical-title
    font-size: 16px
    padding: 0 10px
    background-color: #d8d8d8
</style>
