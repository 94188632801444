<template>
    <v-card>
        <v-container>
            <v-card-title>
                {{ $t('receiptMedical.receptionMedicalConfirm.title_dialog_delete')}}
            </v-card-title>
            <div class="m-3">
                <p v-for="item in dialogMessage" :key="item">{{ item + " 処理を実行します。よろしいですか？"}}</p>
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-center mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.close') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogSelectNotExits",
    props: {
        dialogMessage: [Array, Object]
    },
};
</script>

<style scoped>

</style>