<template>
    <v-card>
        <v-container>
            <v-card-title>
              {{ $t('common.confirm')}}
            </v-card-title>
            <div class="m-3">
                <span>{{"[" + String( modalData.id).padStart(10, '0') + "] " + modalData.patient_name_kana + " 受付取消処理を実行します。よろしいですか？ " }}</span><br>
                <span>支援内容を入力していた場合、全ての支援内容が削除されます。</span>
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="$emit('confirmDialog')">{{ $t('common.ok') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "ConfirmDeleteMedicalDesignate",
    props: {
        modalData: [Array, Object]
    },
};
</script>

<style scoped>

</style>
